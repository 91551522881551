// @import 'global';

//   :host{
//     max-width: 100%;
//   }
    .compWrapper{
        display: inline-block;
        max-width: 100%;

  img {
    //width:500px;
  }
  .background{

    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
  }
  .splash{
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1000;
    
    &:hover{
        background:rgba(0,0,0,0.2);
        .icon-wrapper{
          background: black;
        }
    }
    .icon-wrapper{
      background: gray;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      i{
        color: white;
        font-size: 20px;
      }
    }



  }
  .monitor {

    margin: 0px auto;
    width: 850px;
    max-width: 100%;
    // height: 300px;
    overflow-y: auto;
    border: solid 1em #333;
    border-radius:.5em;

    padding-top: 50%;
    box-sizing: border-box;
    position: relative;
  }
  .monitor::-webkit-scrollbar {
        width: 15px;
  }
  .monitor::-webkit-scrollbar-thumb {
    background: #666;
  }
  ::-webkit-scrollbar-track {
        background-color: #888;
  }
  .comp{
    display: flex;
    flex-direction: column;
    align-items: center;

    //justify-content: flex-end;

    max-width: 100%;

  }

  .stand{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .neck{
      width: 25%;
      padding-top: 10%;
      box-sizing: border-box;
      position: relative;
  
      background: #222;
    }
    .base{
      //box-shadow: $boxShadow;
      background:#333;
      border-top-left-radius:.5em;
      border-top-right-radius:.5em;
      width: 50%;
      height: 15px;
    }
  }
}
