@import 'variables';


.MuiListItem-root{
    //margin-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
}
.MuiListItemText-root{
    margin: 0px;
    margin-left: 10px;
}
.MuiIcon-root{
    color: $softBlack;
    margin-top: 5px;
}


#section-downloads{
    &{
        display: flex;
        flex-grow: 1;

        & > div{
            display: flex;
            flex-grow: 1;
        }
    }
    .info{
        .MuiListItem-root{
            //margin-bottom: 10px;
            padding-left: 0px;
            padding-right: 0px;
        }
        .MuiListItemText-root{
            margin: 0px;
            margin-left: 10px;
        }
        .MuiIcon-root{
            color: $softBlack;
            margin-top: 5px;
        }
    }
    

    .content-wrapper{
        display: flex;
        flex-grow: 1;
        // align-items: center;
        // justify-content: center;
    }

    .summary-wrapper{
        padding-top: 50px;
        padding-bottom: 50px;
        // align-items: stretch;

    }

    .summary-wrapper, .hero-wrapper{
        display: flex;
        flex: 1 1 0;
    }
    .hero-wrapper{
        max-width: 600px;
        // height: 90vh;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 50px;
        padding-right: 16px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        min-height: 0;
        min-width: 0;
    }

    .hero{
        max-width: 100%;
        // display: flex;
        // align-items: center;
        // justify-content: center;
    }

    .mobile, .tablet{

        .hero-wrapper{
            padding-left: 16px;
            height: unset;
        }

        // .hero-wrapper, .summary-wrapper{
        //     padding: 50px 0px;
        //     justify-content: center;
        // }    
    }
    .mobile{
        .content{
            flex-direction: column;
        }
    }
}
