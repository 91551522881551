@import 'variables';

.play-icon{
    color: $softBlack !important;
}

.bullet{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: black;
}