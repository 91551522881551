@import 'variables';


#section-intro{
    .content-wrapper{
        // height: 90vh;
        // display: flex;
        // align-items: center;
        // justify-content: center;
    }
    .content{
        display: flex;
        justify-content: center;

    }
    .summary-wrapper{
        margin-right: 20px;
        //max-width: 250px;
    }
    .content, .hero-wrapper{
        min-height: 0;
        min-width: 0;
    }
    // .hero-wrapper{
    //     flex: 1 1 0;
    //     display: flex;
    //     // justify-content: flex-start;
    // }

    .summary-wrapper, .hero-wrapper{
        display: flex;
        flex: 1 1 0;
    }
    // .summary{
    //     max-width: 250px;
    // }

    .hero{
        max-width: 100%;
        // display: flex;
        // align-items: center;
        // justify-content: center;
    }

    .mobile, .tablet{
        .content-wrapper{
            height: unset;
        }

        .hero-wrapper, .summary-wrapper{
            padding: 50px 0px;
            justify-content: center;
        }    
    }
    .mobile{
        .content{
            flex-direction: column;
        }
    }
}

// #section-binder{
//     .binderWrapperMobile{
//         display: none;
//     }
//     .tablet, .mobile, .desktop{
//         .binderWrapper{
//             max-width: 150px;
//             display: none;
//         }

//         .binderWrapperMobile{
//             display: block;
//         }

//     }
// }

#section-draft{
    .mobile{
        .iconWrapper{
            font-size: 100px;
        }

    }
}

#section-process{
    .MuiStepIcon-text{
        // fill: white;
        font-size: 14px;
    }
    .Mui-active, .Mui-completed{
        // color: $softBlack;
    }
    .vid-controller{
        display: flex;
    }
    .vid-wrapper{
        background: white;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 100% 0%;
        height: 100%;
        width: 100%;
        video{
            max-width: 100%;
            width: 100%;
        }

    }
    .details{
        background: gray;
        height: 100%;
        .summary-wrapper{
            margin: 0px auto;
            padding: 20px;
            ul{
                padding-left: 18px;
                margin: 0px;

            }
            .icon-wrapper{
                //margin-right: 10px;
                i{
                    font-size: 34px;
                }
            }
            .subtitle{
                margin: 10px 0px;
            }
        }
    }

    .mobile{
        .vid-controller{
            .vid-controller-content{
                padding-left: 0px;
                padding-right: 0px;
            }
            .MuiStepLabel-label{
                word-Spacing: 100vw;
            }
        }
    }
}