@import 'variables';


#section-plans{
    .info{
        .MuiListItem-root{
            //margin-bottom: 10px;
            // padding-left: 0px;
            // padding-right: 0px;
        }
        .MuiListItemText-root{
            // margin: 0px;
            // margin-left: 10px;
            margin-top: 8px !important;
        }
        .MuiIcon-root{
            // color: $softBlack;
            // margin-top: 5px;
        }
    }
}
