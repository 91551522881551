@import 'variables';

body{
    font-family: "roboto", sans-serif;
}

body > iframe { display: none } // hide dev app page

.truncate{
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
  
img{
    width: 100%;
    height: auto;
}


.video-container {
    position: relative; 
    padding-bottom: 56.25%;
  }
  
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video, video {
    aspect-ratio: 16 / 9;
    width: 100%;
}

.responsive-table{
    thead {
        display: none;
      }
    tr{
        border-bottom: 8px solid #ddd;
        display: block;
        // margin-bottom: 20px;
    }
    tr:last-of-type{
        border-bottom: 0px
    }
    td, th {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: right !important;
        word-break: break-all;
        border: none;
        // border-bottom: 1px solid #ddd;
        // text-overflow: ellipsis;
        // white-space: nowrap;

        // display: block;
        // font-size: .8em;
        // text-align: right;
    }
    
    td::before, th::before {
        content: attr(data-label);
        font-weight: bold;
        width: 120px;
        min-width: 120px;
        // text-transform: uppercase;
        text-align: left !important;
    }
}

